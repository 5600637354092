<template>
  <div id="proveedor">
    
    <vx-card class="mb-6" >
        <div class="vx-row">
          <div class="vx-col w-full">
              <h1 class="rg-big-title extrabold rg-title">Vendedores <span class="orange">registrados</span>.</h1>
          </div>
        </div>
    </vx-card>

    <vx-card>
      <template >
        <div class="vx-row">
          <div class="vx-col w-full" >
            <p class="bold mb-4">
             Aquí encontrarás la lista de vendedores que has registrado en tu perfil. Puedes dar de alta nuevos vendedores, o bien, editar o eliminar los ya existentes.
            </p>
          </div> 
        </div>
      </template>

      <agents-list></agents-list>
    </vx-card>
  </div>
</template>

<script>
import AgentsList from '../../components/supplier/AgentsList.vue'

export default {
  components:{
    AgentsList
  }
}
</script>